import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, LoadScript, MarkerF, useLoadScript  } from '@react-google-maps/api';
import axiosInstance from './api/axiosInstance';
import redIconUrl from './images/red.png';
import blueIconUrl from './images/blue.png';

const mapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const mapContainerStyle = {
  width: '100%',
  height: '800px'
};

const center = {
    lat: 0,
    lng: 0
};

const neutralMapStyle = [
  {
    elementType: 'geometry',
    stylers: [{ color: '#f5f5f5' }]
  },
  {
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }]
  },
  {
    elementType: 'labels.text.fill',
    stylers: [{ color: '#616161' }]
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#f5f5f5' }]
  },
  {
    featureType: 'administrative',
    elementType: 'geometry',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [{ color: '#eeeeee' }]
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: '#ffffff' }]
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#c9c9c9' }]
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }]
  }
];

const redIcon = {
    url: redIconUrl // Reference the imported custom icon
};

const blueIcon = {
    url: blueIconUrl // Reference the imported custom icon
};

const GoogleMapComponent = () => {

    const { isLoaded } = useLoadScript({
       googleMapsApiKey: mapsApiKey,
    });
    const [markers, setMarkers] = useState([]);
    const mapRef = useRef(null); // Reference for the Google Map instance

    useEffect(() => {
        // Ensure script is fully loaded before making API calls
        if (!isLoaded) return; 

        // Fetch markers from the database with authentication token
        axiosInstance.get('/drivers')
            .then(response => {
                console.log('Drivers fetched successfully:', response.data);
                setMarkers(response.data);

                if (response.data.length > 0 && mapRef.current) {
                    // Calculate bounds and fit map to bounds
                    const bounds = new window.google.maps.LatLngBounds();
                    response.data.forEach((marker) =>
                        bounds.extend({ lat: marker.latitude, lng: marker.longitude })
                    );
                    mapRef.current.fitBounds(bounds); // Adjust center and zoom to fit all markers
                }
            })
            .catch(error => {
                console.error('Error fetching markers:', error);
            });
    }, [isLoaded]);

    if (!isLoaded) return <div>Loading Map...</div>;

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={center}
      zoom={10}
      options={{ styles: neutralMapStyle }}
      onLoad={(map) => (mapRef.current = map)}
    >
            {markers.map((driver, index) => (
                <MarkerF
                    key={index}
                    position={{ lat: driver.latitude, lng: driver.longitude }}
                    label={driver.car_number}
                    icon={driver.activity_status === 'active' ? redIcon : blueIcon}
                />
            ))}
    </GoogleMap>
  );
};

export default GoogleMapComponent;
