import React from 'react';
import GoogleMapComponent from './GoogleMapComponent';

const mapContainer = () => {
    return (
        <div className="MapsComponent">
            <h1>Raspored vozaca na mapi</h1>
            <GoogleMapComponent />
        </div>
    );
};

export default mapContainer;