import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Header from './header';
import Footer from './footer';
import LogIn from './logIn';
import MapContainer from './mapContainer';
import './App.css';

const App = () => {
    return (
        <Router>
            <div style={styles.container}>
                <Header />
                <div style={styles.body}>
                    <Routes>
                        {/* Public route */}
                        <Route path="/" element={<LogIn />} />

                        {/* Private routes */}
                        <Route path="/maps" element={<PrivateRoute element={<MapContainer />} /> } />
                    </Routes>
                </div>
                <Footer />
            </div>
        </Router>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        paddingTop: '60px',  // Make space for the fixed header
        paddingBottom: '40px',  // Make space for the fixed footer
    },
    body: {
        flex: 1,
        padding: '20px',
        overflowY: 'auto',  // Allow body content to scroll
        paddingTop: '20px',
    },
};

export default App;
