import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element, ...rest }) => {
    // Check if the user is authenticated (by checking authToken in localStorage)
    const isAuthenticated = localStorage.getItem('authToken');

    return isAuthenticated ? (
        React.cloneElement(element, { ...rest })  // Render the protected component if authenticated
    ) : (
        <Navigate to="/" replace /> // Redirect to login page if not authenticated
    );
};

export default PrivateRoute;

