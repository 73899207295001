import "./styles.css";
import { useEffect, useRef, useState } from "react";
import axios from "axios";  // Make sure to import axios
import { useNavigate } from "react-router-dom";

export default function Login() {
    const usernameRef = useRef();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);  // State for handling error messages
    const [isLoading, setIsLoading] = useState(false);  // State to track loading

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Basic client-side validation
        if (!username || !password) {
            setError("Username and password are required.");
            return;
        }

        setIsLoading(true);  // Set loading state to true
        setError(null);  // Clear any previous errors

        try {
            // Replace with your actual login API endpoint
            const response = await axios.post("/supervisor/login", {
                username,
                password
            });

            // If login is successful, handle the response
            if (response.data.success) {
                console.log("Login successful!", response.data);
                // You can redirect or update your app state here (e.g., storing tokens)
                const accessToken = response.data.accessToken;
                localStorage.setItem("authToken", accessToken);

                // Set the token in Axios' default headers for future requests
                axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
                navigate("/maps");
            } else {
                console.log("Neki log: "+ response.data.success)
                setError("Login failed: " + response.data.message || "Unknown error");
            }
        } catch (error) {
            // Catch errors in the request, e.g., network errors or server errors
            console.error("Error logging in:", error);
            setError("An error occurred during login.");
        } finally {
            setIsLoading(false);  // Set loading to false after request completes
        }
    };

    useEffect(() => {
        usernameRef.current.focus();  // Focus on the username field when component mounts
    }, []);

    return (
        <div className="App">
            <h1>Login</h1>
            {error && <p className="error-message">{error}</p>}  {/* Display error message */}
            <form onSubmit={handleSubmit} className="form__container">
                <div className="form__controls">
                    <label htmlFor="username">Username</label>
                    <input
                        ref={usernameRef}
                        type="text"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div className="form__controls">
                    <label htmlFor="password">Password</label>
                    <input
                        id="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div className="form__controls">
                    <button className="button" disabled={isLoading}>
                        {isLoading ? "Logging in..." : "Login"}
                    </button>
                </div>
            </form>
        </div>
    );
}
